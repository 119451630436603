* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --light-transparent: rgba(255, 255, 255, 0.75);
  --dark-transparent: rgba(0, 0, 0, 0.5);
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

html {
  font-size: calc(100vw / 1728 * 10);
}

body {
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

*::-webkit-scrollbar {
  display: none;
}

/* custom mouse */

.hero-images img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* lenis */

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* misc */

.container {
  max-width: 156.6rem;
  padding-inline: 5rem;
}

.dark-overlay {
  display: none;
}

section {
  width: 100%;
  min-height: 100vh;
  background: black;
}

h1,
h2 {
  font-size: 16rem;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 1;
}

p {
  font-size: 4.8rem;
  letter-spacing: -0.03em;
  line-height: 1.5;
}

.hero {
  display: grid;
  place-items: center;
  height: 129.8rem;
  background: black;
}

.hero-title {
  text-align: center;
  max-width: 10ch;
  z-index: 5;
  mix-blend-mode: difference;
  color: white;
}

.hero-title div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero img {
  position: absolute;
}

.hero img:nth-child(1) {
  width: 37.4rem;
  height: 56.1rem;
  top: 0;
  left: 8rem;
}

.hero img:nth-child(2) {
  width: 24.1rem;
  height: auto;
  top: 0;
  left: 61.1rem;
}
.hero img:nth-child(3) {
  width: 35.1rem;
  height: auto;
  top: 0;
  right: 8rem;
}
.hero img:nth-child(4) {
  width: 37.4rem;
  height: auto;
  top: 16.8rem;
  right: 46rem;
}
.hero img:nth-child(5) {
  width: 35.1rem;
  height: auto;
  top: 65.9rem;
  left: 47.8rem;
}
.hero img:nth-child(6) {
  width: 24.1rem;
  height: auto;
  top: 93.7rem;
  left: 21.3rem;
}
.hero img:nth-child(7) {
  width: 63.9rem;
  height: 70%;
  top: 81.5rem;
  right: 8rem;
}

/* text block section */

.text-block {
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-block p {
  color: #ffffff;
}

.text-block-content {
  max-width: 44ch;
}

/* slider */

.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
}

.gallery img {
  width: 30%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.slider-section {
  height: 100vh;
  min-height: unset;
  overflow: hidden;
}

.scroll-container {
  height: 100vh;
  width: fit-content;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
.slide {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border: none;
  background-color: white;
  padding: 1.6rem 4.8rem;
  margin: 5rem;
  font-size: 2.4rem;
  color: black;
  border-radius: 8px;
  font-family: inherit;
  letter-spacing: -0.01em;
  z-index: 300;
}
.text-effect {
  position: relative;
}
.text-overlay {
  display: block;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.5;
  transform-origin: center right;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer header {
  color: white;
  font-size: 36px;
  margin-bottom: 20vh;
  width: 100%;
  text-align: center;
  padding: 0 25px;
}

form {
  /* height: 400px; */
  width: 80%;
  border: 1px solid var(--light-transparent);
  border-radius: 15px;
  padding: 10px;
  margin: 25px 20px 10vh;
}

form .inputBox-container {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

form .inputBox-container .spacer {
  height: 1px;
  width: 95%;
  background: var(--white);
}

form .inputBox {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form .inputBox input {
  all: unset;
  width: 100%;
  height: 100%;
  padding: 0 0 0 20px;
  font-size: 18px;
  overflow: hidden;
  color: var(--white);
  cursor: pointer;
}

form .inputBox input::placeholder {
  font-size: 18px;
  color: var(--white);
}

form .inputBox .inputBox-message-input {
  font-size: 18px;
}

form .inputBoxBtn-Container {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form .inputBoxBtn {
  background: var(--white);
  color: var(--black);
  font-size: 22px;
  padding: 5px 10px;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
  border: 1px solid var(--white);
}

form .inputBoxBtn:hover {
  background: var(--black);
  color: var(--white);
}

@media screen and (min-width: 999.1px) {
  [data-hidden] {
    opacity: 0;
  }
}

/* Media Query for Mobile Screens (below 768px) */
@media screen and (max-width: 999px) {
  .slide img {
    width: 90vw; /* Each image takes up 100% of the viewport width */
  }
}

@media screen and (max-width: 999px) {

  .hero {
    max-height: 300px;
    overflow: hidden;
  }

  .hero-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .hero img {
    position: relative;
  }

  .dark-overlay {
    display: initial;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background: var(--dark-transparent);
    z-index: 3;
  }

  .hero-images img {
    display: none;clip-path: polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%);
  }
  
  .hero img:nth-child(1) {
    display: initial;
    width: 100%;
    height: 25vh;
    top: unset;
    left: unset;
    right: unset;
    left: unset;
  }
  .hero img:nth-child(2) {
    display: initial;
    width: 100%;
    height: 25vh;
    top: unset;
    left: unset;
    right: unset;
    left: unset;
  }
  .hero img:nth-child(3) {
    display: initial;
    width: 100%;
    height: 25vh;
    top: unset;
    left: unset;
    right: unset;
    left: unset;
  }
  .hero img:nth-child(4) {
    display: initial;
    width: 100%;
    height: 25vh;
    top: unset;
    left: unset;
    right: unset;
    left: unset;
  }
  
}

@media screen and (max-width: 768px) {
  .hero {
    display: flex;
    place-items: unset;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: black;
  }

  .hero-title {
    font-size: 32rem;
  }

  .text-block {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .text-block p {
font-size: 13rem;
  }

  button {
    font-size: 10rem;
  }
}